<template>
  <v-menu
    v-model="dataOpen"
    bottom
    offset-y
    content-class="menu"
    v-if="availableGlobalActions.length"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        x-small
        color="primary"
        v-on="on"
        class="ml-4"
      >
        <icon-tooltip
          bottom
          :disabled="dataOpen"
          icon-name="i.Plus"
          :tooltip-text="$t('t.Add')"
        />
      </v-btn>
    </template>
    <v-card class="d-flex flex-row">
      <div class="d-flex flex-column overflow-y-auto overflow-x-hidden">
        <div
          class="d-flex"
          v-if="actions.length || genericActions.length"
        >
          <v-list class="flex-grow-1">
            <v-subheader v-if="actions.length && settingsActions.length">{{$t('t.Documents')}}</v-subheader>
            <v-list-item
              v-for="(action, idx) in actions"
              :key="idx"
              link
              @click="action.callback()"
            >
              <v-icon class="mr-4">{{action.icon}}</v-icon>
              <v-list-item-content>{{action.typeName}}</v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            class="flex-grow-1 ml-8 overflow-y"
            v-if="genericActions.length"
          >
            <v-subheader v-if="actions.length && genericActions.length && settingsActions.length" />
            <v-list-item
              v-for="(action, idx) in genericActions"
              :key="idx"
              link
              @click="action.callback()"
            >
              <v-icon class="mr-4">{{action.icon}}</v-icon>
              <v-list-item-content>{{action.typeName}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-divider v-if="(actions.length || genericActions.length) && settingsActions.length" />
        <v-list
          v-if="settingsActions.length"
          class="flex-grow-1"
        >
          <v-subheader v-if="actions.length || genericActions.length">{{$t('t.Settings')}}</v-subheader>
          <v-list-item
            v-for="(action, idx) in settingsActions"
            :key="idx"
            link
            @click="action.callback()"
          >
            <v-icon class="mr-4">{{action.icon}}</v-icon>
            <v-list-item-content>{{action.typeName}}</v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script>

import globalActions from '@/mixins/global-actions'

export default {
  mixins: [globalActions],
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      dataOpen: false
    }
  },
  computed: {
    actions () {
      const actions = this.availableGlobalActions.filter(_ => {
        return _.documentType !== 'generic-task' && !this.docTypeAsSettings.includes(_.documentType)
      })

      return actions
    },
    docTypeAsSettings () {
      return ['labels',
        'aging-scenarios',
        'business-divisions',
        'label-groups',
        'work-queues',
        'columns',
        'escalation-protocols',
        'processes',
        'providers']
    },
    genericActions () {
      const actions = this.availableGlobalActions.filter(_ => {
        return _.documentType === 'generic-task'
      })

      return actions
    },
    settingsActions () {
      let actions = this.availableGlobalActions.filter(_ => {
        return this.docTypeAsSettings.includes(_.documentType)
      })
      actions = actions.sort((a, b) => a.typeName.toLowerCase() < b.typeName.toLowerCase() ? -1 : 1)

      return actions
    }
  }
}
</script>

<style lang="stylus" scoped>
.overflow-y
  overflow-y auto

.no-overflow
  overflow hidden

.menu
  max-height 90%
  overflow hidden
  display flex
</style>
